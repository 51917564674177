import * as React from 'react';
import * as classnames from 'classnames/bind';

const styles = require('./HSpacer.less');
const cx = classnames.bind(styles) as any;

export interface HSpacerProps
{
    size:number;
}

export class HSpacer extends React.Component<HSpacerProps, any>
{
    render()
    {
        const {size} = this.props;
        return  <span style={{display:'inline-block', width:size}}/>;
    }
}